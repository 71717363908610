import React from 'react';
const InnerContainer = ({ title, children, imagename, imgTag }) => {
    let image_url = "../../../../images/";
    return (
        <>
           <div className='pageContainer'>
                <div className='title'>{imgTag && <img className='icon' src={image_url + `${imagename}`} />}{title}</div>
                <div className='pageInner'>{children}</div>
            </div>
        </>
    );
};

export default InnerContainer