import React from "react"


const Table = ({ columns, tableRows }) => {
    return (
        <>
            <div className="table-responsive">
                <table className="tableCustom table">
                    <thead>
                        <tr>
                            {columns && columns.map((column, index) => (
                                <th key={index}>{column}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
            </div>
        </>
    )
}

export default Table

