import React, { useEffect, useState } from 'react';
import InnerContainer from "../components/InnerContainer";
import Table from "../components/table";
import Constants from '../../utils/Constants';
import Api from '../../api/Api';
import { useLocation } from 'react-router-dom';
import moment from "moment-timezone";

const DrawAndResult = () => {
    const location = useLocation();
    const [categoriesData, setCategoriesData] = useState([]);
    const [NoRecord, setNoRecord] = useState(false);
    const pathname = location.pathname;
    const tournamentId = pathname.split('/').pop();

    const tableColumns = [
        'Date',
        'Match Stage',
        'Draw',
        'Result',
    ];

    const resultList = () => {
        Api.call(`${Constants.GET_DRAW_AND_RESULTS}?id=${tournamentId}&categoryGroup=1`)
            .then((data) => {
                if (data && Object.keys(data).length > 0) {
                    setCategoriesData(data);
                    setNoRecord(true);
                } else {
                    setNoRecord(false);
                }
            })
            .catch(() => {
                setNoRecord(false);
            });
    };

    useEffect(() => {
        resultList();
    }, []);

    return (
        <div className='drawAndResult'>
            {NoRecord ? (
                Object.keys(categoriesData).map((categoryKey, categoryIndex) => (
                    <div key={categoryIndex}>
                        <h3 className="table-heading">{categoryKey}</h3>
                        <Table
                            columns={tableColumns}
                            tableRows={categoriesData[categoryKey].length > 0 ? (
                                categoriesData[categoryKey].map((item, index) => (
                                    <tr key={index}>
                                        <td width="20%" data-title="Date">{moment(item.createdAt).format('DD MMM YYYY')}</td>
                                        <td width="20%" data-title="Match Stage">{item.matchState || ''}</td>
                                        <td width="20%" data-title="Draw">
                                            {item.draw ? <a href={item.draw} target="_blank" rel="noopener noreferrer">Draw</a> : ''}
                                        </td>
                                        <td width="20%" data-title="Result">
                                            {item.result ? <a href={item.result} target="_blank" rel="noopener noreferrer">Result</a> : ''}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr><td colSpan="100%" style={{ textAlign: "center" }}>No records found</td></tr>
                            )}
                        />
                    </div>
                ))
            ) : (
                <div style={{ textAlign: "center" }}>No records found</div>
            )}
        </div>
    );    
};

export default DrawAndResult;
